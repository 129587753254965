import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faBars, faCaretRight, faFileAlt, faBroadcastTower, faPencilRuler, faVideo, faFile, faFileSignature, faCaretDown } from '@fortawesome/free-solid-svg-icons'

library.add(faFacebookF, faMapMarkerAlt, faTwitter, faInstagram, faYoutube, faBars, faCaretRight, faFileAlt, faBroadcastTower, faPencilRuler, faVideo, faFile, faFileSignature, faCaretDown)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.prototype.$circuitDiagramsListItems = [
  { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/circuit/overview.pdf', imagePath: require('@/assets/images/aircraft_circuit/overview_page1.pdf.png-150.png'), documentName: 'Circuit Overview' },
  { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/circuit/eurofox-3k.pdf', imagePath: require('@/assets/images/aircraft_circuit/eurofox-3k_page1.pdf.png-150.png'), documentName: 'Eurofox 3K Circuit Diagram' },
  { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/circuit/cessna-152.pdf', imagePath: require('@/assets/images/aircraft_circuit/cessna-152_page1.pdf.png-150.png'), documentName: 'Cessna 152 Circuit Diagram' },
  { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/circuit/cessna-172r.pdf', imagePath: require('@/assets/images/aircraft_circuit/cessna-172r_page1.pdf.png-150.png'), documentName: 'Cessna 172R Circuit Diagram' },
  { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/circuit/cessna-182.pdf', imagePath: require('@/assets/images/aircraft_circuit/cessna-182_page1.pdf.png-150.png'), documentName: 'Cessna 182 Circuit Diagram' },
  { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/circuit/piper-pa34-220t.pdf', imagePath: require('@/assets/images/aircraft_circuit/piper-pa34-220t_page1.pdf.png-150.png'), documentName: 'Piper PA-34 220T Circuit Diagram' }
]

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
