import Vue from 'vue'
import VueRouter from 'vue-router'
import news from '@/views/news/news.js'

Vue.use(VueRouter)

const normalRoutes = [
  {
    path: '/',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/contact',
    meta: {
      title: 'Contact'
    },
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/recreational-pilot-certificate',
    meta: {
      title: 'Recreational Pilot Certificate'
    },
    component: () => import('@/views/programs/RecreationalPilotCertificate.vue')
  },
  {
    path: '/recreational-pilot-licence',
    meta: {
      title: 'Recreational Pilot Licence'
    },
    component: () => import('@/views/programs/RecreationalPilotLicence.vue')
  },
  {
    path: '/ra-aus-navigation-endorsement',
    meta: {
      title: 'RA-Aus Navigation Endorsement'
    },
    component: () => import('@/views/programs/RAAusNavigationEndorsement.vue')
  },
  {
    path: '/private-pilot-licence',
    meta: {
      title: 'Private Pilot Licence'
    },
    component: () => import('@/views/programs/PrivatePilotLicence.vue')
  },
  {
    path: '/ra-aus-flight-instructor-course',
    meta: {
      title: 'RA-Aus Flight Instructor Course'
    },
    component: () => import('@/views/programs/RAAusFlightInstructorCourse.vue')
  },
  {
    path: '/ga-flight-instructor-course',
    meta: {
      title: 'Grade 3 Flight Instructor Course'
    },
    component: () => import('@/views/programs/GAFlightInstructorCourse.vue')
  },
  {
    path: '/ra-aus-passenger-carrying-endorsement',
    meta: {
      title: 'RA-Aus Passenger Carrying Endorsement'
    },
    component: () => import('@/views/programs/RAAusPassengerCarryingEndorsement.vue')
  },
  {
    path: '/commercial-pilot-licence',
    meta: {
      title: 'Commercial Pilot Licence'
    },
    component: () => import('@/views/programs/CommercialPilotLicence.vue')
  },
  {
    path: '/multi-engine-rating',
    meta: {
      title: 'Multi-Engine Class Rating'
    },
    component: () => import('@/views/programs/MultiEngineRatingCourse.vue')
  },
  {
    path: '/rpc-to-rpl-conversion',
    meta: {
      title: 'RPC to RPL Conversion'
    },
    component: () => import('@/views/programs/RPCToRPLConversion.vue')
  },
  {
    path: '/uprt-upset-prevention-and-recovery-training',
    meta: {
      title: 'UPRT - Upset Prevention and Recovery Training'
    },
    component: () => import('@/views/programs/UPRT.vue')
  },
  {
    path: '/tailwheel-endorsement',
    meta: {
      title: 'Tailwheel Endorsement'
    },
    component: () => import('@/views/programs/TailwheelEndorsement.vue')
  },
  {
    path: '/formation-flying',
    meta: {
      title: 'Formation Flying'
    },
    component: () => import('@/views/programs/FormationFlying.vue')
  },
  {
    path: '/aerobatic-endorsement',
    meta: {
      title: 'Aerobatic Endorsement'
    },
    component: () => import('@/views/programs/AerobaticEndorsement.vue')
  },
  {
    path: '/about-us',
    meta: {
      title: 'About Us'
    },
    component: () => import('@/views/AboutUs.vue')
  },
  {
    path: '/faq',
    meta: {
      title: 'FAQ'
    },
    component: () => import('@/views/FAQ.vue')
  },
  {
    path: '/location',
    meta: {
      title: 'Location'
    },
    component: () => import('@/views/Location.vue')
  },
  {
    path: '/programs',
    meta: {
      title: 'Programs'
    },
    component: () => import('@/views/Programs.vue')
  },
  {
    path: '/student-resources',
    meta: {
      title: 'Student Resources'
    },
    component: () => import('@/views/StudentResources.vue')
  },
  {
    path: '/student-faq',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/StudentFAQ.vue')
  },
  {
    path: '/how-to-apply-for-arn',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToApplyARN.vue')
  },
  {
    path: '/how-to-apply-for-raaus',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToApplyRAAus.vue')
  },
  {
    path: '/how-to-apply-for-medical',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToApplyMedical.vue')
  },
  {
    path: '/what-are-medical-obligations',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/WhatAreMedicalObligations.vue')
  },
  {
    path: '/how-to-check-weather',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToCheckWeather.vue')
  },
  {
    path: '/how-to-find-air-legislation',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToFindAirLegislation.vue')
  },
  {
    path: '/how-to-find-documentation',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToFindDocumentation.vue')
  },
  {
    path: '/how-to-purchase-aviation-headset',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToPurchaseAviationHeadset.vue')
  },
  {
    path: '/how-to-manage-currency',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToManageCurrency.vue')
  },
  {
    path: '/how-to-weight-balance',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToWeightAndBalance.vue')
  },
  {
    path: '/how-to-todr-ldr',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToTODRLDR.vue')
  },
  {
    path: '/how-to-submit-update',
    meta: {
      title: 'Student FAQ'
    },
    component: () => import('@/views/student-resources/student-faq/HowToSubmitUpdate.vue')
  },
  {
    path: '/videos',
    meta: {
      title: 'Videos'
    },
    component: () => import('@/views/student-resources/Videos.vue')
  },
  {
    path: '/flightscope-documentation',
    meta: {
      title: 'Flightscope Aviation Documentation'
    },
    component: () => import('@/views/student-resources/FlightscopeDocumentation.vue')
  },
  {
    path: '/homework-sheets',
    meta: {
      title: 'Homework Sheets'
    },
    component: () => import('@/views/student-resources/HomeworkSheets.vue')
  },
  {
    path: '/fleet',
    meta: {
      title: 'Fleet'
    },
    component: () => import('@/views/Fleet.vue')
  },
  {
    path: '/news',
    meta: {
      title: 'News'
    },
    component: () => import('@/views/News.vue')
  }
]

const redirects = [
  { path: '/student-resources/aircraft', redirect: '/aircraft-documentation' },
  { path: '/student-resources/homework-sheets', redirect: '/homework-sheets' },
  { path: '/student-resources/videos', redirect: '/videos' },
  { path: '/about-us/location', redirect: '/location' },
  { path: '/about-us/team', redirect: '/about-us' },
  { path: '/news/airspace-changes-21-may-2020', redirect: '/airspace-changes-21-may-2020' },
  { path: '/news/kieran-brown-flying-scholarship', redirect: '/kieran-brown-flying-scholarship' },
  { path: '/news/ra-aus-now-allowed-to-fly-in-tour-hours', redirect: '/ra-aus-now-allowed-to-fly-in-tower-hours' },
  { path: '/programs/private-pilot-licence', redirect: '/private-pilot-licence' },
  { path: '/programs/ra-aus-flight-instructor-course', redirect: '/ra-aus-flight-instructor-course' },
  { path: '/programs/ra-aus-navigation-endorsement', redirect: '/ra-aus-navigation-endorsement' },
  { path: '/programs/recreational-pilot-certificate', redirect: '/recreational-pilot-certificate' },
  { path: '/programs/recreational-pilot-licence', redirect: '/recreational-pilot-licence' }
]

const newsRoutes = news.map(entry => ({
  path: '/' + entry.id,
  meta: {
    title: entry.title
  },
  component: () => import('@/views/news/' + entry.id + '.md')
}))

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    ...normalRoutes,
    ...newsRoutes,
    ...redirects,
    {
      path: '*',
      meta: {
        title: 'Page Not Found'
      },
      component: () => import('@/views/PageNotFound.vue')
    }
  ]
})

export default router
