<template>
  <div class="topnav" id="topnav">
    <router-link to="/" exact><img src="@/assets/images/logo/logo-straight.800.png" alt="Flightscope Logo" /></router-link>
    <router-link @click.native="itemClick()" class="nav-link" to="/" exact>Home</router-link>
    <router-link @click.native="itemClick()" class="nav-link" to="/programs">Programs</router-link>
    <router-link @click.native="itemClick()" class="nav-link" to="/fleet">Fleet</router-link>
    <router-link @click.native="itemClick()" class="nav-link" to="/student-resources">Student Resources</router-link>
    <router-link @click.native="itemClick()" class="nav-link" to="/news">News</router-link>
    <router-link @click.native="itemClick()" class="nav-link" to="/faq">FAQ</router-link>
    <router-link @click.native="itemClick()" class="nav-link" to="/about-us">About Us</router-link>
    <router-link @click.native="itemClick()" class="nav-link" to="/location">Location</router-link>
    <router-link @click.native="itemClick()" class="get-in-touch" to="/contact">Get In Touch</router-link>
    <a href="javascript:void(0);" class="icon" @click="burgerClick()">
      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'bars' }" />
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    burgerClick () {
      var x = document.getElementById('topnav')
      if (x.className === 'topnav') {
        x.className += ' responsive'
      } else {
        x.className = 'topnav'
      }
    },
    itemClick () {
      var x = document.getElementById('topnav')
      if (x.className === 'topnav responsive') {
        x.className = 'topnav'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.topnav {
  overflow: hidden;
  padding: 1vh;
}

.get-in-touch {
  @extend .rounded;
  float: right;
  display: block;
  background-color: $primary;
  color: $secondary;

  &:hover {
    background-color: $primary-hover;
    color: $secondary;
  }
}

img {
  height: 1em;
  vertical-align: middle;
}

a {
  float: left;
  display: block;
  color: $primary;
  text-align: center;
  padding: 1em;
  text-decoration: none;

  &:hover {
    color: $primary-hover;
  }
}

.nav-link::after {
  content: '';
  transform: scaleX(0);
  height: 1px;
  display: block;
  background: black;
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
}

.nav-link.router-link-active {
  color: $primary-hover;
  &::after {
    content: '';
    transform: scaleX(1);
    height: 1px;
    display: block;
    background: black;
  }
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive a.icon {
    position: absolute;
    right: 1vh;
    top: 1vh;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
</style>
