const news = [
  {
    id: 'kieran-brown-flying-scholarship',
    title: 'Kieran Brown Flying Scholarship',
    description: 'Kieran Brown Memorial Flying Scholarship',
    date: new Date('August 20, 2019')
  },
  {
    id: 'ra-aus-now-allowed-to-fly-in-tower-hours',
    title: 'RA-Aus Allowed To Fly In Tower Hours',
    description: 'We are now allowed to send our RA Aus students (and RPC holders) solo during tower hours here at Archerfield.',
    date: new Date('May 28, 2020')
  },
  {
    id: 'airspace-changes-21-may-2020',
    title: 'Airspace Changes 21 May 2020',
    description: 'From 21 May 2020, the designated airspace around Archerfield and Sunshine Coast airports will be undergoing a number of changes.',
    date: new Date('May 21, 2020')
  },
  {
    id: 'increased-traffic-alerts-28-jan-2021',
    title: 'Increased Traffic Alerts 28 Jan 2021',
    description: 'Recently, our instructors have been receiving an increasing number of traffic alerts from Brisbane Centre in the area between Target and Mt Cotton, while in Class G (uncontrolled) airspace.',
    date: new Date('Jan 28, 2021')
  },
  {
    id: '20210508-flying-fun-bbq',
    title: 'Flying Fun & BBQ 08 May 2021',
    description: 'Flightscope Aviation is hosting the Flying Fun & Barbecue on Saturday 08 May 2021. All are welcome; current students, prior students, aspiring students or just anyone who loves hanging out and talking aeroplanes.',
    date: new Date('April 16, 2021')
  }
]

export default news
