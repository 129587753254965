<template>
  <div id="app">
    <Navbar />
    <router-view />
    <Contact />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Contact from '@/components/Contact.vue'

export default {
  components: {
    Navbar,
    Contact
  },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title ? 'Flightscope Aviation | ' + to.meta.title : 'Flightscope Aviation'
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');

* {
  line-height: 1.8em;
}

body {
  margin: 0;
  font-size: calc(1em + 0.3vh);
}

#app {
  font-family: Raleway, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary;
}
</style>
